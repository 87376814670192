import Register from '../utils/Register';
import autocomplete from 'autocompleter';
import naja from 'naja';

export default class SearchBox {

	constructor (input) {
		autocomplete({
			input: input,
			fetch: function(text, callback) {
				naja.makeRequest('GET', '/search/ajax/', {
					q: text
				}, {
					history: false,
					spinner: false
				}).then((payload) => {
					callback(payload);
				});
			},
			onSelect: function(item) {
				window.location.href = item.link;
			},
			render: function(item) {
				var div = document.createElement('div');
				div.classList = 'js-search-list-item';
				div.innerHTML = '<div class="d-flex align-items-center">' +
					'<div style="width: 50px;height: 50px;margin: 5px;">' + (item.image !== '' ? '<img style="object-fit: contain; height: 100%; width: 100%;" width="100%" height="100%" src="/' + item.image + '" >' : '<i style="font-size: 30px;padding: 10px;color: #ddd;" class="fa-solid fa-ban"></i>') + '</div>' +
					'<div><strong class="d-block">' + item.name + '</strong><small>' + item.code + '</small></div>' +
					'</div>';
				return div;
			},
			debounceWaitMs: 200,
			emptyMsg: 'Nic nebylo nalezeno.',
			className: 'js-search-list'
		});
	}

	static init ($container) {
		$container.filterAllNodes('.js-search-input').each(function(index, element) {
			new SearchBox(element);
		});
	}

}

(new Register()).registerCallback(SearchBox.init, 'SearchBox.init');